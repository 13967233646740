.mui-field {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px !important;
  margin-bottom: 16px;

  .MuiInputBase-root {
    border-radius: 4px;
    &::before {
      border: none;
    }

    &::after {
      border: none;
    }
  }

  input {
    padding: 0 14px;
    height: 40px;
  }

  .mui-field-label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 2px;
  }
  .mui-field-value {
    flex-flow: nowrap !important;
    width: 100%;

    .MuiFormControl-root {
      width: 100%;
    }

    .field-icon {
      margin-top: 5px;
      margin-right: 5px;
    }
  }
  .mui-field-footer-label {
    font-style: oblique;
    font-size: 14px;
    width: 100%;
    padding-bottom: unset;
  }
}

.standard-field {
  border-bottom: 2px solid #c6d9f1;
}
