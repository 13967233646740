html {
  width: 100%;
  height: 100%;
}

body {
  color: #1f497d !important;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

.MuiBackdrop-root {
  // background: linear-gradient(
  //   100deg,
  //   rgba(0, 64, 87, 0.7) 0%,
  //   rgba(0, 64, 87, 1) 100%
  // );
}

a {
  color: #2b7fe0;
  text-decoration: underline;
}

.MuiInputBase-root {
  border-radius: 4px;
}

.MuiDataGrid-row:hover {
  cursor: pointer;
}

.MuiModal-root {
  z-index: 9999;
}

.rotateIcon {
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.MuiLoadingButton-loading {
  color: #616161 !important;

  .MuiLoadingButton-loadingIndicator {
    color: #1f497d !important;
  }
}

.otpInputStyle {
  width: 40px !important;
  height: 40px;
  margin: 0 8px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
